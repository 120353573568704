const selectedDataPoints = []

/**
 * Data point structure
 * [
 *  {
 *    "url": "url", // prefix for the IP the DB is in
 *    "data": [
 *      {
 *        "equipmentName": "equipmentName", // used by Feathers to identify the schema name
 *        "equipmentDataPoints": ["point1", "point2"], // labels for columns in schema
 *      },
 *      {
 *        "equipmentName": "equipmentName",
 *        "equipmentDataPoints": ["point3"],
 *      }
 *    ]
 *  }
 * ]
 */
export default {
  getDataPoints: function () {
    return [...selectedDataPoints]
  },
  // returns all of the data points for each equipment
  getTotalNumberOfPoints: function () {
    let counter = 0
    selectedDataPoints.forEach((dataSet) => {
      dataSet.data.forEach((equipment) => {
        equipment.equipmentDataPoints.forEach((point) => {
          counter++
        })
      })
    })
    return counter
  },
  // Adds new data point to global var to be turned into a dashboard later
  // returns Boolean to show if added or not to list
  addDataPoint: function (equipmentName, dataLabel, dataHumanLabel, dataUrl) {
    // TODO: Store this as a cookie or something? Is lost on refresh.

    // don't use forEach if you need to exit the loop before iterating through all elements.
    // forEach is a function call and will execute on all data points regardless of break/return in the block
    for (var i = 0; i < selectedDataPoints.length; i++) {
      if (selectedDataPoints[i].url.toLowerCase().localeCompare(dataUrl.toLowerCase()) === 0) {
        var element = selectedDataPoints[i]
        for (var j = 0; j < element.data.length; j++) {
          if (element.data[j].equipmentName.toLowerCase().localeCompare(equipmentName.toLowerCase()) === 0) {
            for (var k = 0; k < element.data[j].equipmentDataPoints.length; k++) {
              if (!element.data[j].equipmentDataPoints[k].dataLabel.localeCompare(dataLabel === 0)) {
                return false // data point exists! do not add
              }
            }
            element.data[j].equipmentDataPoints.push({
              dataLabel: dataLabel,
              dataHumanLabel: dataHumanLabel,
            })

            return true // we checked the points and it doesn't exist
          }
        }

        // the data object for this equipment does not exist yet on the given url
        // there is no chance that there will be a url without a data object as both would be added
        // when a new url is added to the list
        element.data.push({
          equipmentName: equipmentName,
          equipmentDataPoints: [{
            dataLabel: dataLabel,
            dataHumanLabel: dataHumanLabel,
          }],
        })
        return true // new data point for new equipment to monitor on this url is pushed. DONE
      }
    }

    // either we have no urls in the list yet, or the url doesn't exist in the list
    selectedDataPoints.push({
      url: dataUrl,
      data: [
        {
          equipmentName: equipmentName,
          equipmentDataPoints: [{
            dataLabel: dataLabel,
            dataHumanLabel: dataHumanLabel,
          }],
        },
      ],
    })

    return true
  },

  getDataPointsForEquipment: function (equipmentName, dataUrl) {
    for (var i = 0; i < selectedDataPoints.length; i++) {
      if (selectedDataPoints[i].url.toLowerCase().localeCompare(dataUrl.toLowerCase()) === 0) {
        var element = selectedDataPoints[i]
        for (var j = 0; j < element.data.length; j++) {
          if (element.data[j].equipmentName.toLowerCase().localeCompare(equipmentName.toLowerCase()) === 0) {
            return element.data[j].equipmentDataPoints
          }
        }
      }
    }
  },

  removeDataForEquipment: function (equipmentName, dataUrl) {
    for (var i = 0; i < selectedDataPoints.length; i++) {
      if (selectedDataPoints[i].url.toLowerCase().localeCompare(dataUrl.toLowerCase()) === 0) {
        var element = selectedDataPoints[i]
        for (var j = 0; j < element.data.length; j++) {
          if (element.data[j].equipmentName.toLowerCase().localeCompare(equipmentName.toLowerCase()) === 0) {
            element.data.splice(j, 1)
            return 0
          }
        }
      }
    }

    return -1
  },

  clear: function () {
    selectedDataPoints.length = 0
  },
}
