<template>
  <v-container fluid>
    <v-row class="justify-center">
      <div class="text-h1 mb-1 grey--text text--darken-2 font-weight-medium">
        {{ equipmentDisplayName }}
      </div>
    </v-row>
    <v-row justify="center">
      <v-col
        cols="12"
        lg="4"
        class="pa-0"
      >
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-card>
            <v-img
              :src="equipmentImageSrc"
              contain
            />
          </v-card>
        </v-col>
        <v-col
          cols="12"
        >
          <v-alert
            v-if="showAlertSetpoints"
            type="error"
          >
            We are unable to adjust your equipment’s Setpoints at this time. Please try again later.
          </v-alert>
          <v-card v-if="showSetpoints">
            <v-card-title
              class="text-h3 font-weight-bold grey--text justify-center"
            >
              Setpoints
            </v-card-title>
            <v-sheet
              width="80%"
              style="margin-right: 10%; margin-left: 10%;"
            >
              <v-slider
                v-if="showTemperatureSetpoint"
                v-model="temperatureSetpoint"
                class="mt-4"
                color="grey darken-1"
                track-color="grey darken-1"
                max="80"
                min="60"
                step="1"
                ticks="always"
                tick-size="4"
              >
                <template v-slot:label="">
                  <span>
                    Temperature:
                  </span>
                  <span style="font-weight: bold; font-size: 16px;">
                    {{ `${temperatureSetpoint} &#176;F` }}
                  </span>
                </template>
              </v-slider>
            </v-sheet>
            <v-card-actions class="justify-center pa-4 pt-0 mt-0">
              <v-btn
                id="equipmentSetpointUpdateButton"
                color="green darken-1"
                text
                :loading="setpointUpdateLoader"
                @click="updateSetpoints"
              >
                Update
              </v-btn>
              <v-btn
                color="grey darken-1"
                text
                @click="resetSetpoints"
              >
                Reset
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-col>
      <v-col
        cols="12"
        lg="8"
      >
        <v-alert
          v-if="showAlert"
          type="error"
          class="mt-2"
        >
          {{ alertMessage }}
        </v-alert>
        <v-card>
          <v-card-title class="d-flex flex-wrap">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <div
              class="mt-2 d-flex align-center"
              style="width: 100%;"
            >
              <v-icon
                class="mr-1"
                small
              >
                mdi-clock-outline
              </v-icon>

              <span
                class="text-caption grey--text font-weight-light"
              > {{ `Table last updated at: ${lastTableUpdate}` }}</span>
            </div>
          </v-card-title>
          <v-data-table
            id="equipmentDashboardDataTable"
            v-model="selectedData"
            item-key="name"
            class="elevation-1"
            :loading="loading"
            :loading-text="statusText"
            hide-default-footer
            :headers="equipmentHeaders"
            :items="equipmentData"
            :items-per-page="100"
            show-select
            :search="search"
            @item-selected="onChangeSelection"
            @toggle-select-all="onToggleSelectAll"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-speed-dial
        v-model="fab"
        bottom
        right
        fixed
      >
        <template v-slot:activator>
          <v-tooltip
            left
            open-delay="1000"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                :color="fabCounter > 0 ? 'green' : 'transparent'"
                :content="fabCounter"
                overlap
              >
                <v-btn
                  id="equipmentFab"
                  color="blue darken-2"
                  dark
                  v-bind="attrs"
                  fab
                  v-on="on"
                >
                  <v-icon v-if="fab">
                    mdi-close
                  </v-icon>
                  <v-icon v-else>
                    mdi-chart-multiple
                  </v-icon>
                </v-btn>
              </v-badge>
            </template>
            <span>Chart Functions</span>
          </v-tooltip>
        </template>
        <v-tooltip
          left
          open-delay="1000"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="equipmentCreateChartButton"
              fab
              dark
              small
              color="green"
              v-bind="attrs"
              @click="createChart()"
              v-on="on"
            >
              <v-icon>mdi-chart-line</v-icon>
            </v-btn>
          </template>
          <span>Create Chart</span>
        </v-tooltip>
        <v-tooltip
          left
          open-delay="1000"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="indigo"
              v-bind="attrs"
              @click="showSelectedDataModal()"
              v-on="on"
            >
              <v-icon>mdi-clipboard-list-outline</v-icon>
            </v-btn>
          </template>
          <span>View Selected Points</span>
        </v-tooltip>
      </v-speed-dial>
    </v-row>
    <v-row>
      <v-dialog
        :key="dataPointsDialog[dataPointsDialog.length-1] ?
          dataPointsDialog[dataPointsDialog.length-1].id : ''"
        v-model="showDataPointDialog"
        persistent
        max-width="500"
      >
        <v-card v-if="dataPointsDialog.length > 0">
          <v-container class="d-flex justify-center">
            <v-card-title class="text-h5 text-center font-weight-bold">
              Selected data points
            </v-card-title>
          </v-container>
          <v-data-table
            v-model="selectedDataPointsDialog"
            item-key="id"
            class="elevation-1"
            hide-default-footer
            :headers="dataPointsHeaders"
            :items="dataPointsDialog"
            show-select
            group-by="equipmentName"
            show-group-by
          />
          <div class="d-flex justify-center">
            <v-card-actions>
              <v-btn
                color="green darken-1"
                text
                @click="saveDataPointsDialog"
              >
                Save
              </v-btn>
              <v-btn
                color="orange darken-2"
                text
                @click="clearDataPointsDialog"
              >
                Clear
              </v-btn>
              <v-btn
                color="red darken-1"
                text
                @click="showDataPointDialog = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
        <v-card
          v-else
          class="d-flex align-center flex-column pa-12"
        >
          <span>
            {{ dataPointDialogText }}
          </span>
          <v-btn
            color="green darken-1"
            width="100px"
            class="mt-6"
            text
            @click="showDataPointDialog = false"
          >
            Ok
          </v-btn>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showInfoDialog"
        persistent
        max-width="300"
      >
        <v-card>
          <v-card-title class="text-h5">
            Selected data points
          </v-card-title>
          <v-card-text>
            {{ infoDialogText }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green darken-1"
              text
              @click="showInfoDialog = false"
            >
              Okay
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <chart-dialog
      v-if="showChart"
      :key="selectedData.length"
      :chart-initial-data="chartDataPoints"
      :is-preview="true"
      @close-dialog="dismissChartDialog"
    />

    <material-snackbar
      v-model="showSnackbar"
      type="info"
      timeout="3000"
      v-bind="{
        ['bottom']: true,
        ['left']: true
      }"
    >
      {{ snackBarMessage }}
    </material-snackbar>
    <material-snackbar
      v-model="showChartSavedSnackbar"
      type="info"
      timeout="3000"
      v-bind="{
        ['bottom']: true,
        ['left']: true
      }"
    >
      <span class="font-weight-bold">&nbsp;CHART SAVED&nbsp;</span> — Head to the Dashboard tab to see it!
    </material-snackbar>
  </v-container>
</template>

<script>
  import equipmentDataPointHolder from '@/util/equipment-data-point-holder.js'
  import equipmentApi from '@/api/equipment.js'
  import common from '@/api/common.js'
  import { bus } from '@/main'
  import { equipmentSetpointEnums } from '@/util/enums'
  import store from '@/store'

  export default {
    name: 'EquipmentView',

    data: () => ({
      equipmentName: null,
      equipmentDisplayName: '',
      equipmentDataPath: '',
      equipmentImageSrc: '',
      equipmentData: [],
      chart: null,
      equipmentHeaders: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Value',
          value: 'value',
          filterable: false,
        },
      ],
      selectedData: [],
      search: '',
      error: false,
      loading: true,
      statusText: '',
      showAlert: false,
      alertMessage: '',
      alertMessageGeneral: 'There was an error loading this equipment data. Please try again later.',
      alertMessageStaleData: 'We have not received any new data from the controller in the past 5 minutes. The equipment may be down for testing, or the controller is not responding.',
      showInfoDialog: false,
      showDataPointDialog: false,
      showChartTitleDialog: false,
      showChart: false,
      infoDialogText: '',
      fab: false,
      fabHidden: true,
      pollyPoller: null,
      pollysFriendPaulaPoller: null,
      lastTableUpdate: '',
      dataTableId: -1,
      staleDataCount: 0,

      dataPointsDialog: [],
      dataPointDialogText: '',
      selectedDataPointsDialog: [],
      dataPointsHeaders: [
        {
          text: 'Equipment',
          value: 'equipmentName',
          align: 'start',
        },
        {
          text: 'Name',
          value: 'humanName',
          align: 'right',
          groupable: false,
        },
      ],
      showSnackbar: false,
      snackBarMessage: '',
      snackBarMessageSave: 'Changes have been saved!',
      snackBarMessageClear: 'Data points cleared!',
      snackBarMessageSetpointSuccess: 'The equipment’s Setpoints have been updated successfully!',

      fabCounter: 0,

      dict: {},
      tempPointList: [],

      chartTitle: 'chartychartchart',
      chartData: {
        labels: [],
        datasets: [],
      },
      chartDataPoints: null,
      showChartSavedSnackbar: false,

      showAlertSetpoints: false,
      setpointUpdateLoader: false,
      showSetpoints: false,
      initialSetpointList: [],
      temperatureSetpoint: 0,
      showTemperatureSetpoint: false,
    }),

    created () {
      this.statusText = 'Loading... Please wait.'
      this.getEquipmentInformation()
      this.fabCounter = equipmentDataPointHolder.getTotalNumberOfPoints()

      bus.$on('chartSaved', () => {
        this.showChart = false
        this.showChartSavedSnackbar = true
      })
    },

    beforeDestroy () {
      clearInterval(this.pollyPoller)
      clearInterval(this.pollysFriendPaulaPoller)

      console.log('destroying equipment view, updating points')
      this.updateEquipmentDataPoints()
      console.log(equipmentDataPointHolder.getDataPoints())
    },
    methods: {
      getEquipmentInformation: function () {
        equipmentApi.getEquipmentInformation(this.$route.params.equipmentId)
          .then(response => {
            console.log('equipment information', response)

            // TODO: everything that uses equipmentName be changed to use either equipmentId or equipmentDisplayName instead?
            this.equipmentName = response.data.feathersName
            this.equipmentDisplayName = response.data.displayName
            if (response.data.imagePath) {
              this.equipmentImageSrc = require('../assets/' + response.data.imagePath)
            } else {
              this.equipmentImageSrc = require('../assets/equip/default_equipment_image.jpg')
            }

            const dataHumanNames = response.data.humanNames
            dataHumanNames.forEach(dataPoint => {
              if (dataPoint.pointName && dataPoint.pointHumanName) {
                this.dict[dataPoint.pointName] = {
                  pointHumanName: dataPoint.pointHumanName,
                  pointValueMap: dataPoint.pointValueMap,
                }
              }
            })

            this.initialSetpointList = response.data.setpoints
            if (this.initialSetpointList.length > 0) {
              this.showSetpoints = true
              this.initialSetpointList.forEach(setpoint => {
                const setpointType = equipmentSetpointEnums.get(setpoint.equipmentSetpointEnumId)
                if (setpointType === 'temperature') {
                  this.showTemperatureSetpoint = true
                  this.temperatureSetpoint = setpoint.value
                }
              })
            }

            this.equipmentDataPath = response.data.feathersDataPath
            this.getEquipmentTableData()
            this.callPollyPoller()

            const breadcrumbs = response.data.breadcrumbs.map(crumb => {
              return { title: crumb.name, to: crumb.url }
            })
            breadcrumbs.unshift({ title: 'Site', to: '/site/' })
            store.set('app/breadCrumbs', breadcrumbs)
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.getEquipmentInformation()
            } else {
              this.equipmentImageSrc = require('../assets/equip/default_equipment_image.jpg')
              this.loading = false
              this.showAlert = true
              this.alertMessage = this.alertMessageGeneral
            }
          })
      },

      getEquipmentTableData: function () {
        equipmentApi.getInitialGraphData(this.equipmentName, this.equipmentDataPath)
          .then(response => {
            console.log('data table response', response)
            this.loading = true
            this.showAlert = false
            var tempData = []
            for (var key in response.data.data[0]) {
              if (key === 'id') {
                if (this.dataTableId === response.data.data[0][key]) {
                  this.staleDataCount++
                  if (this.staleDataCount >= 5) {
                    this.showAlert = true
                    this.alertMessage = this.alertMessageStaleData
                  }
                } else {
                  this.dataTableId = response.data.data[0][key]
                  this.staleDataCount = 0
                }
              } else if (key === 'updatedAt') {
                let dateString = response.data.data[0][key].substring(0, response.data.data[0][key].length - 2)
                dateString += 'Z'
                this.lastTableUpdate = new Date(dateString).toString()
              } else if (key !== 'time' && key !== 'createdAt') {
                var label = this.equipmentTitleMapper(key)
                tempData.push({
                  name: label,
                  value: this.equipmentValueMapper(key, response.data.data[0][key]),
                })
              }
            }
            this.equipmentData = tempData

            // check for selected items
            if (this.selectedData.length === 0 &&
              equipmentDataPointHolder.getDataPointsForEquipment(this.equipmentName, this.getEquipmentUrl())) {
              equipmentDataPointHolder.getDataPointsForEquipment(this.equipmentName, this.getEquipmentUrl())
                .forEach(dataPoint => {
                  this.selectedData.push({
                    name: this.equipmentTitleMapper(dataPoint.dataHumanLabel),
                  })
                })
            }
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.getEquipmentTableData()
            } else {
              this.loading = false
              this.showAlert = true
              this.alertMessage = this.alertMessageGeneral

              // TODO: Temporary possibly
              clearInterval(this.pollyPoller)
              clearInterval(this.pollysFriendPaulaPoller)
              var tempData = []
              this.tempPointList.forEach(point => {
                var label = this.equipmentTitleMapper(point)
                tempData.push({
                  name: label,
                  value: 0,
                })
              })
              this.equipmentData = tempData
              // check for selected items
              if (this.selectedData.length === 0 &&
                equipmentDataPointHolder.getDataPointsForEquipment(this.equipmentName, this.getEquipmentUrl())) {
                equipmentDataPointHolder.getDataPointsForEquipment(this.equipmentName, this.getEquipmentUrl())
                  .forEach(dataPoint => {
                    this.selectedData.push({
                      name: this.equipmentTitleMapper(dataPoint.dataHumanLabel),
                    })
                  })
              }
            }
          })
      },

      callPollyPoller: function () {
        this.pollyPoller = setInterval(function () {
          this.getEquipmentTableData()
        }.bind(this), 60000) // 1 minute = 60 seconds * 1000 miliseconds
      },

      createChart: function () {
        this.updateEquipmentDataPoints()

        if (equipmentDataPointHolder.getDataPoints().length > 0) {
          this.showChart = true
          this.chartDataPoints = {
            id: 0,
            title: 'Preview Chart',
            datasets: equipmentDataPointHolder.getDataPoints(),
            colorList: [''],
          }
          this.selectedData = []
          equipmentDataPointHolder.clear()
          this.fabCounter = 0
        } else {
          this.infoDialogText = 'There are no datapoints selected. Please select datapoints from equipment to create a trending data graph.'
          this.showInfoDialog = true
        }
      },

      showSelectedDataModal: function () {
        // get list of selected items from global
        if (equipmentDataPointHolder.getDataPoints().length === 0 &&
          this.selectedData.length === 0) {
          this.dataPointDialogText = 'Data points you have selected from other pieces of equipment will appear here.'
        } else {
          this.updateEquipmentDataPoints()

          const dataPoints = equipmentDataPointHolder.getDataPoints()
          let id = 0
          this.dataPointsDialog = dataPoints.map(dataSet => {
            return dataSet.data.map((equipment) => {
              const equipmentPoints = []
              equipment.equipmentDataPoints.forEach((point) => {
                equipmentPoints.push({
                  dataName: point.dataLabel,
                  humanName: point.dataHumanLabel,
                  equipmentPath: dataSet.url,
                  equipmentName: equipment.equipmentName,
                  id: id,
                })
                id++
              })
              return equipmentPoints
            }).flat()
          }).flat()

          this.selectedDataPointsDialog = this.dataPointsDialog
        }
        // show items to user to select or remove from list
        // make changes to global list when user closes it?

        this.showDataPointDialog = true
      },

      // Because there isn't a fancier way of handling diffs between removing selections, just delete it all
      // and re-add it because why not?
      updateEquipmentDataPoints: function () {
        console.log('update')
        console.log('selected', this.selectedData)
        equipmentDataPointHolder.removeDataForEquipment(this.equipmentName, this.getEquipmentUrl())
        const currentSelectedData = [...this.selectedData]
        currentSelectedData.forEach(dataPoint => {
          equipmentDataPointHolder.addDataPoint(
            this.equipmentName,
            this.objectKeyByValue(this.dict, dataPoint.name),
            dataPoint.name,
            this.getEquipmentUrl(),
          )
        })
      },

      getEquipmentUrl: function () {
        return 'http://' + this.equipmentDataPath
      },

      programmaticChart: function () {
        this.showChart = true
        this.chartChildRef = this.chartData
      },

      equipmentTitleMapper: function (label) {
        if (this.dict[label]) {
          return this.dict[label].pointHumanName
        } else {
          return label
        }
      },
      equipmentValueMapper: function (label, value) {
        if (this.dict[label]?.pointValueMap?.[value]) {
          return this.dict[label].pointValueMap[value]
        } else {
          return value
        }
      },

      objectKeyByValue: function (obj, val) {
        var key = Object.entries(obj).find(i => i[1].pointHumanName === val)
        if (key) {
          return key[0]
        } else {
          return val
        }
      },

      saveDataPointsDialog: function () {
        console.log('selected dialog points', this.selectedDataPointsDialog)
        equipmentDataPointHolder.clear()
        this.selectedData = []
        this.selectedDataPointsDialog.forEach(dataPoint => {
          equipmentDataPointHolder.addDataPoint(
            dataPoint.equipmentName,
            dataPoint.humanName,
            dataPoint.dataName,
            dataPoint.equipmentPath,
          )
          if (dataPoint.equipmentName === this.equipmentName) {
            this.selectedData.push({ name: this.equipmentTitleMapper(dataPoint.humanName) })
          }
        })

        this.showDataPointDialog = false
        this.snackBarMessage = this.snackBarMessageSave
        this.showSnackbar = true
        this.fabCounter = equipmentDataPointHolder.getTotalNumberOfPoints()
      },

      clearDataPointsDialog: function () {
        this.selectedData = []
        this.dataPointsDialog = []
        equipmentDataPointHolder.clear()
        this.fabCounter = 0

        this.showDataPointDialog = false
        this.snackBarMessage = this.snackBarMessageClear
        this.showSnackbar = true
      },

      onChangeSelection: function (e) {
        if (e.value === true) {
          this.fabCounter++
        } else {
          this.fabCounter--
        }
      },

      onToggleSelectAll: function (e) {
        if (e.value === true) {
          this.fabCounter = e.items.length
        } else {
          this.fabCounter = 0
        }
      },

      dismissChartDialog: function () {
        this.showChart = false
      },

      updateSetpoints: function () {
        this.showAlertSetpoints = false
        this.setpointUpdateLoader = true
        let skipFinallyBlock = false
        this.initialSetpointList.forEach((setpoint, i) => {
          if (equipmentSetpointEnums.get(setpoint.equipmentSetpointEnumId) === 'temperature') {
            equipmentApi
              .setSetpointsForEquipment({ equipmentId: this.$route.params.equipmentId, setpointId: setpoint.id, setpointValue: this.temperatureSetpoint })
              .then(response => {
                console.log('setpoint response', response)
                this.showSnackbar = true
                this.snackBarMessage = this.snackBarMessageSetpointSuccess
              })
              .catch(async error => {
                console.log(error)
                if (await common.handleBadCall(error, this.$router) === true) {
                  skipFinallyBlock = true
                  this.updateSetpoints()
                } else {
                  this.showAlertSetpoints = true
                }
              })
              .finally(() => {
                if (!skipFinallyBlock) {
                  this.setpointUpdateLoader = false
                }
              })
          }
        })
      },

      resetSetpoints: function () {
        this.initialSetpointList.forEach((setpoint, i) => {
          if (equipmentSetpointEnums.get(setpoint.equipmentSetpointEnumId) === 'temperature') {
            this.temperatureSetpoint = setpoint.value
          }
        })
      },

    },
  }
</script>
